import { ACTION_TYPES } from "./../constants";
import produce from "immer";
import {
  dismissNotices,
  errorHandler,
  itemsHandler,
  loadingHandler,
} from "./_helpers";

const dataActions = ACTION_TYPES.DATA_ACTIONS;
const apiActions = ACTION_TYPES.API_ACTIONS;
const noticeActions = ACTION_TYPES.NOTICE_ACTIONS;
export const SET_GOAL_WITNESS_LIST = "SET_GOAL_WITNESS_LIST";

const initialState = {
  items: [],
  goalWitnessList: [],
  totalElements: 0,
  numberOfElements: 0,
  showUsersDialog: false,
  isLoading: false,
  error: undefined,
  message: undefined,
};

const userDialogHandler = (state, payload) => {
  return produce(state, (draft) => {
    draft.showUsersDialog = payload;
  });
};

const setGoalWitness = (state, payload) => {
  return { ...state, goalWitnessList: [...payload] };
};

const getUsersSuccessHandler = itemsHandler;

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case dataActions.userDialog: {
      return userDialogHandler(state, payload);
    }

    case dataActions.getUsersSuccess: {
      return getUsersSuccessHandler(state, payload);
    }

    case dataActions.getUsersError: {
      return errorHandler(state, payload);
    }

    case apiActions.getUsers: {
      return loadingHandler(state, true);
    }

    case dataActions.setGoalWitnessList: {
      return setGoalWitness(state, payload);
    }

    case dataActions.resetGoalWitnessList: {
      return { ...state, goalWitnessList: [] };
    }

    case noticeActions.usersDismiss: {
      return dismissNotices(state);
    }
    default:
      return state;
  }
};

export default usersReducer;
