import React from 'react';
import {createTheme, darken, lighten, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {main: '#071126', light: lighten('#071126', 0.1), dark: darken('#071126', 0.1)},
        secondary: {main: '#61D0E9', light: lighten('#61D0E9', 0.1), dark: darken('#61D0E9', 0.1)},
    },
    sidebarWidth: '260px',
    userAvatarSize: '60px'
})

const Theme = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default Theme;