import React from 'react';
import {logo} from "../assets/images";
import {ImageBox} from "./index";

const Logo = (props) => {
    return (
        <ImageBox src={logo}
                  styles={props.sx}
                  alt={"Company logo"}/>
    );
};

export default Logo;