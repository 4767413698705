import produce from "immer";
import {ACTION_TYPES} from "../constants";
import {dismissNotices, errorHandler} from "./_helpers";

const actions = ACTION_TYPES.AUTH_ACTIONS;
const noticeActions = ACTION_TYPES.NOTICE_ACTIONS;

const initialState = {
    isLoggedIn: false,
    bearerToken: undefined,
    refreshToken: undefined,
    isRefreshingToken: false,
    refreshCall: undefined,
    message: undefined,
    error: undefined
}

const _loginSuccessHandler = (state, data) => {
    return produce(state, draft => {
        draft.bearerToken = data.bearerToken;
        draft.refreshToken = data.refreshToken;
        draft.isLoggedIn = true;
        draft.error = undefined;
    })
};

const _logoutHandler = (state) => {
    return produce(state, draft=>{
        draft.bearerToken = undefined;
        draft.refreshToken = undefined;
        draft.isLoggedIn = false;
        draft.error = undefined;
    })
};

const _refreshStateHandler = (state, payload)=>{
    return produce(state, draft=>{
        draft.isRefreshingToken = payload;
    })
}
const _refreshCallHandler = (state, payload)=>{
    return produce(state, draft=>{
        draft.refreshCall = payload;
    })
}

const authReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case (actions.refreshTokenSuccess):
        case (actions.logInSuccess): {
            return _loginSuccessHandler(state, payload);
        }

        case (actions.refreshTokenError):
        case (actions.logOut):{
            return _logoutHandler(state);
        }

        case (actions.refreshCall):{
            return _refreshCallHandler(state, payload);
        }

        case (actions.refreshState):{
            return _refreshStateHandler(state, payload);
        }

        case (actions.logInError):{
            return errorHandler(state,payload);
        }

        case (noticeActions.authDismiss):{
            return dismissNotices(state);
        }

        default:
            return state;
    }
};

export default authReducer;