import React from 'react';
import {Box, useTheme} from "@mui/material";
import {useSelector} from "react-redux";

const Header = () => {
    const {palette} = useTheme();
    const isLoggedIn = useSelector(state=>state.auth.isLoggedIn);

    if(!isLoggedIn) return null;
    return (
        <Box sx={{
            backgroundColor: palette.background.default,
        }}>
        </Box>
    );
};

export default Header;