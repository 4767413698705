import {logOut} from "../helpers";

const nav = [
    {label: 'Active users', href: '/', key: 'nav-goals'},
    {label: 'Send invitations', href: '/invite', key: 'nav-invite'},
    {label: 'Weekly participants', href: '/pairs', key: 'nav-pairs'},
    {label: 'Leader Post', href: '/message', key: 'nav-message'},
    {label: 'Logout', href: '/login', onClick: logOut, key: 'nav-logout', sx: {marginTop: '1.5rem'}},

];

export default nav;