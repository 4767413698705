import {
    loginHandler,
    loginSuccessHandler,
    quickLoginHandler,
    refreshTokenSuccessHandler, refreshTokenErrorHandler, logOutHandler
} from "./handlers";
import {ACTION_TYPES} from "../../constants";

const authActions = ACTION_TYPES.AUTH_ACTIONS;
// const apiActions = ACTION_TYPES.API_ACTIONS;

const authMiddleware = store => {
    return next => action => {
        const dispatch = store.dispatch;
        const result = next(action);
        const {payload, type} = action;

        switch (type) {
            case (authActions.logIn):
                loginHandler(payload, dispatch);
                break;

            case (authActions.logInQuick): {
                quickLoginHandler(store, dispatch);
                break;
            }

            case (authActions.logInSuccess): {
                loginSuccessHandler(payload);
                break;
            }

            case (authActions.logOut): {
                logOutHandler()
                break;
            }

            case (authActions.refreshTokenSuccess): {
                refreshTokenSuccessHandler(payload);
                break;
            }

            case (authActions.refreshTokenError): {
                refreshTokenErrorHandler(payload);
                break;
            }


            default:
                break;
        }

        return result;
    }
}

export default authMiddleware;