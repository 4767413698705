import React from 'react';
import propTypes from 'prop-types';
import {Pagination as MuiPagination} from '@mui/material';

//Pagination starts with 1, not 0
const Pagination = (props) => {
    const {
        pagesCount,
        currentPage,
        changeHandler,
        styles,
        firstPage,
        lastPage
    } = props;

    if(lastPage && firstPage) return null;

    const onChange = (event, pageNumber) => {
        //Pagination starts with 1, not 0
        changeHandler(event, pageNumber - 1);
    }

    return (
        <MuiPagination count={pagesCount}
                        //Pagination starts with 1, not 0
                       page={currentPage + 1}
                       siblingCount={0}
                       onChange={onChange}

                       sx={styles}
                       variant="outlined"
                       color="primary"
                       hideNextButton={lastPage}
                       hidePrevButton={firstPage}
                       boundaryCount={1}/>
    );
};

Pagination.propTypes = {
    pagesCount: propTypes.number.isRequired,
    currentPage: propTypes.number.isRequired,
    changeHandler: propTypes.func.isRequired,
    firstPage: propTypes.bool,
    lastPage: propTypes.bool
}

export default Pagination;