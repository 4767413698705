import {ACTION_TYPES} from "../constants";

const actions = ACTION_TYPES.NOTICE_ACTIONS;

const dismissAuthNoticeAction = () => {
    return dispatch => dispatch({
        type: actions.authDismiss
    });
};

const dismissInvitesMessageAction = () => {
    return dispatch => dispatch({
        type: actions.invitesDismiss
    });
};

const dismissGoalsMessageAction = () => {
    return dispatch => dispatch({
        type: actions.goalsDismiss
    });
};

const dismissPairsMessageAction = () => {
    return dispatch => dispatch({
        type: actions.pairsDismiss
    });
};

const dismissUsersMessageAction = () => {
    return dispatch => dispatch({
        type: actions.usersDismiss
    });
};

export {
    dismissAuthNoticeAction,
    dismissInvitesMessageAction,
    dismissGoalsMessageAction,
    dismissUsersMessageAction,
    dismissPairsMessageAction
}