import {ACTION_TYPES} from "../constants";

const dataActions = ACTION_TYPES.DATA_ACTIONS;

const _getPairsHandler = (payload) => {
    return {
        type: dataActions.getPairs,
        payload
    }
}

const getPairsAction = (payload) => {
    return dispatch => dispatch(_getPairsHandler(payload))
};

/*const setCurrentGoalAction = payload => {
    return dispatch =>dispatch({
        type: dataActions.setCurrentGoal,
        payload
    })
};*/

const clearCurrentGoalAction = () => {
    return dispatch =>dispatch({
        type: dataActions.clearCurrentGoal
    })
}

export {getPairsAction, /*setCurrentGoalAction,*/ clearCurrentGoalAction};