import store from "../../store";
import {ACTION_TYPES} from "../../constants";
import {destroyLoginTokens, getLoginTokens, logOut, saveLoginTokens} from "../../helpers";

const apiActions = ACTION_TYPES.API_ACTIONS;

const loginHandler = (payload) => {
    store.dispatch({
        type: ACTION_TYPES.API_ACTIONS.logIn,
        payload
    });
};

const quickLoginHandler = (store, dispatch) => {
    let {refreshToken} = store.getState().auth;

    if (!refreshToken) {
        const tokens = getLoginTokens();
        refreshToken = tokens.refreshToken;
    }

    if (refreshToken) {
        dispatch({
            type: apiActions.refreshToken,
            payload: {
                refreshToken
            }
        })
    }else{
        logOut();
    }
}

const loginSuccessHandler = (payload) => {
    saveLoginTokens(payload);
};

const refreshTokenSuccessHandler = (payload) => {
    saveLoginTokens(payload)
}

const refreshTokenErrorHandler = () => {
    logOut();
}

const logOutHandler = () => {
    destroyLoginTokens();
};

export {
    loginHandler,
    quickLoginHandler,
    loginSuccessHandler,
    refreshTokenSuccessHandler,
    refreshTokenErrorHandler,
    logOutHandler
};