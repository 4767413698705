import {ACTION_TYPES} from "../constants";
import produce from "immer";
import {errorHandler, itemsHandler, loadingHandler} from "./_helpers";

const dataActions = ACTION_TYPES.DATA_ACTIONS;
const apiActions = ACTION_TYPES.API_ACTIONS;

const initialState = {
    items: [],
    currentPair: undefined,
    totalElements: 0,
    numberOfElements: 0,
    totalPages: 0,
    pageNumber: 0,
    isLoading: false
}

const getPairsSuccessHandler = itemsHandler;

const setPairHandler = (state, data) => {
    return produce(state, draft => {
        draft.currentPair = data;
    })
};

const pairsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {

        case (apiActions.getPairs): {
            return loadingHandler(state, true);
        }

        case (dataActions.getPairsSuccess): {
            return getPairsSuccessHandler(state, payload);
        }

        case (dataActions.getPairsError):{
            return errorHandler(state, payload)
        }

        case (dataActions.setCurrentGoal): {
            return setPairHandler(state, payload);
        }

        case (dataActions.clearCurrentGoal): {
            return setPairHandler(state, undefined);
        }

        default:
            return state;
    }
}

export default pairsReducer;