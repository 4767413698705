import React from 'react';
import {Box, useTheme} from "@mui/material";
import {Sidebar, Header} from "./index";
import {Outlet} from "react-router-dom";


const Layout = () => {
    const {palette, sidebarWidth} = useTheme();
    return (
        <Box className="App" sx={{
            background: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.secondary.main})`,
            minHeight: '100vh',
            display: 'flex'
        }}>
            <Sidebar/>
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
                '.c-sidebar+&':{
                    maxWidth: `calc(100% - ${sidebarWidth})`,
                }
            }}>
                <Header/>
                <Outlet/>
            </Box>
        </Box>
    );
};

export default Layout;