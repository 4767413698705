const login = [
    {
        type: 'text',
        name: 'email',
        id: 'login-email',
        placeholder: 'Enter your email',
        label: 'Email'
    },
    {
        type: 'password',
        name: 'password',
        id: 'login-password',
        placeholder: 'Enter your password',
        label: 'Password'
    }
];

const emails = [
    {
        type: 'text',
        name: 'email',
        id: 'add-email',
        placeholder: 'Enter email',
        label: 'Email'
    }
]

export {login, emails};