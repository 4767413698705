import { API } from "../constants";
import axios from "axios";
import _pick from "lodash/pick";
import _replace from "lodash/replace";
import store from "../store";
import { refreshStateAction } from "../actions";
import Cookies from "js-cookie";
import { makeRefreshTokenRequest } from "../middleware/api/handlers";

//TODO: if error send for new refresh token & repeat the request
//      if error persists - reject
//make requests with custom axios instance or default axios (for login, register etc.)

const makeBaseRequest = (routeKey, payload, instance = axios) => {
  const params = _getRequestParams(routeKey, payload);

  if (!params) {
    return Promise.reject({ message: "Error sending request!" });
  }

  return instance(params);
};

const _getRequestParams = (routeKey, payload) => {
  let baseUrl = API.API_URL;
  let data = undefined;

  if (!baseUrl) {
    console.warn("Api URL is not declared!");
    return false;
  }

  const routeConfig = API.ROUTES[routeKey];

  if (!routeConfig) {
    return false;
  }

  const pathReplaced = _pathVarsReplacer(
    routeConfig.path,
    routeConfig.pathVars,
    payload
  );
  let params = {
    method: routeConfig.method,
    url: baseUrl + pathReplaced,
  };

  if (routeConfig.vars) {
    data = _pick(payload, routeConfig.vars, "");


    if (routeConfig.method === "post") {
      params.data = data;
    }

    if (routeConfig.method === "get") {
      params.params = data;
    }

    if (routeConfig.method === "put" && routeConfig.vars.length === 0) {

      params.data = payload;
    }
    if (routeConfig.customPut) {

      params.data = data;
    }
  }

  return params;
};

const _pathVarsReplacer = (path = "", vars = [], data) => {
  if (!vars.length) return path;

  return vars.reduce((prevVal, currVal) => {
    const replaceValue = data && currVal in data ? data[currVal] : "";
    return _replace(prevVal, `$${currVal}`, replaceValue);
  }, path);
};

const makeRequest = (route, payload, isRefresh = false) => {
  const clientInstance = _getRequestClientInstance(isRefresh, payload);
  return makeBaseRequest(route, payload, clientInstance);
};

const _getRequestClientInstance = (isRefresh, refreshPayload) => {
  const { getState } = store;
  const instance = axios.create();
  const { bearerToken } = getState().auth;
  const token = Cookies.get("refreshToken", { path: "/" });

  if (isRefresh) {
    instance.defaults.headers.common["refresh_token"] = token;
  } else {
    if (bearerToken) {
      instance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${bearerToken}`;
    }
  }

  instance.interceptors.response.use(
    (data) => data,
    _requestClientErrorHandler
  );

  return instance;
};

//if request failed:
// 1. update refresh token
// 2. try again

const _requestClientErrorHandler = (error) => {
  const { status } = error.response;
  const originalRequest = error.config;

  if (status === 401) {
    return refreshTokenRequest().then(
      ({ data }) => {
        originalRequest.headers["Authorization"] = `Bearer ${data.bearerToken}`;
        return axios.request(originalRequest);
      },

      (error) => {
        return Promise.reject(error);
      }
    );
  }

  return Promise.reject(error);
};

const refreshTokenRequest = () => {
  const storeState = store.getState();
  const dispatch = store.dispatch;

  const { isRefreshingToken, refreshCall } = storeState.auth;

  if (isRefreshingToken) {
    return refreshCall;
  }

  refreshStateAction(dispatch, true);

  makeRefreshTokenRequest("refreshToken", dispatch);

  refreshStateAction(dispatch, false);
  // const newRefreshCall = makeBaseRequest('refreshToken', {refresh_token: token});

  // newRefreshCall
  //     .then(_refreshTokenSuccessHandler, _refreshTokenErrorHandler)
  //     .finally(() => {
  //         refreshStateAction(dispatch, false);
  //         refreshCallAction(dispatch, undefined);
  //     });

  // refreshCallAction(dispatch, newRefreshCall);

  // return newRefreshCall;
};

// const _refreshTokenSuccessHandler = (response) => {
//     store.dispatch(refreshTokenSuccessHandler(response));
//     return Promise.resolve(response.data);
// };

// const _refreshTokenErrorHandler = (error) => {
//     refreshTokenErrorHandler(error)
//     return Promise.reject(error);
// };

export { makeBaseRequest, makeRequest };
