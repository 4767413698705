import React from 'react';
import propTypes from "prop-types";
import FilterView from "./view";

const Filter = (props) => {
    return (
        <FilterView {...props}/>
    );
};

Filter.propTypes = {
    label: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    showClear: propTypes.bool,
    onClearClick: propTypes.func
};

export default Filter;