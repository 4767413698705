import _debounce from 'lodash/debounce';
import {ACTION_TYPES} from "../../constants";

const apiActions = ACTION_TYPES.API_ACTIONS;

const _getGoalsRaw = (dispatch, payload) => {
    dispatch({
        type: apiActions.getGoals,
        payload
    })
};

const getGoalsHandler = _debounce(_getGoalsRaw, 500, {trailing: true});

const _getPairsRaw = (dispatch, payload) => {
    dispatch({
        type: apiActions.getPairs,
        payload
    })
};

const getPairsHandler = _debounce(_getPairsRaw, 500, {trailing: true});

const _getUsersRaw = (dispatch, payload) => {
    dispatch({
        type: apiActions.getUsers,
        payload
    });
};

const getUsersHandler = _debounce(_getUsersRaw, 500, {trailing: true});

const _getInviteRaw = (dispatch, payload) => {
    dispatch({
        type: apiActions.getInvite,
        payload
    });
};

const getInviteHandler = _debounce(_getInviteRaw, 500, {trailing: true});

const pageChangeHandler = (dispatch, initialPayload, state) => {
    const {type, page} = initialPayload;

    let payload = undefined;

    if (type === 'users') {
        payload = {...state.filters.users, page}
        getUsersHandler(dispatch, payload);
    } else if (type === 'goals') {
        payload = {...state.filters.goals, page}
        getGoalsHandler(dispatch, payload);
    } else if (type === 'pairs') {
            payload = {...state.filters.pairs, page}
            getPairsHandler(dispatch, payload);
    } else if (type === 'invite') {
        payload = {...state.filters.goals, page}
        getInviteHandler(dispatch, payload);
    }
}

export {
    getGoalsHandler,
    getPairsHandler,
    getUsersHandler,
    getInviteHandler,
    pageChangeHandler
}