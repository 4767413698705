import {ACTION_TYPES} from "../constants";
import produce from "immer";
import {errorHandler, itemsHandler, loadingHandler} from "./_helpers";

const dataActions = ACTION_TYPES.DATA_ACTIONS;
const apiActions = ACTION_TYPES.API_ACTIONS;

const initialState = {
    items: [],
    currentGoal: undefined,
    totalElements: 0,
    numberOfElements: 0,
    totalPages: 0,
    pageNumber: 0,
    isLoading: false
}

const getGoalsSuccessHandler = itemsHandler;

const setGoalHandler = (state, data) => {
    return produce(state, draft => {
        draft.currentGoal = data;
    })
};

const goalsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {

        case (apiActions.getGoals): {
            return loadingHandler(state, true);
        }

        case (dataActions.getGoalsSuccess): {
            return getGoalsSuccessHandler(state, payload);
        }

        case (dataActions.getGoalsError):{
            return errorHandler(state, payload)
        }

        case (dataActions.setCurrentGoal): {
            return setGoalHandler(state, payload);
        }

        case (dataActions.clearCurrentGoal): {
            return setGoalHandler(state, undefined);
        }

        default:
            return state;
    }
}

export default goalsReducer;