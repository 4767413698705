import React from 'react';
import propTypes from "prop-types";
import PersonIcon from '@mui/icons-material/Person';
import {Avatar as AvatarMUI} from "@mui/material";
import './avatar.css';
const Avatar = (props) => {
    const {imageSrc, styles} = props;
    if(imageSrc){
        return <AvatarMUI src={imageSrc} sx={{
            width: '60px',
            height:'60px',
            ...styles}}/>
    }

    return (
        <AvatarMUI sx={styles}>
            <PersonIcon/>
        </AvatarMUI>
    );
};

Avatar.propTypes = {
    imageSrc: propTypes.string,
}

export default Avatar;