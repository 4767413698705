import * as yup from "yup";

const _email = yup
    .string('Enter your email')
    .email('Enter a valid email')

const loginSchema = yup.object({
    email: _email.required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

const emailSchema = yup.object({
    email: _email,
})

export {
    loginSchema,
    emailSchema
};