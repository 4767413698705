import React from 'react';
import propTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";

const AuthWrapper = (props) => {
    const {requireAuth = false, children} = props;
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const location = useLocation();

    if(!requireAuth || (requireAuth && isLoggedIn)) {
        return children;
    }

    return <Navigate to={"/login"} state={{from: location}}/>
};

AuthWrapper.propTypes = {
    requireAuth: propTypes.bool
}

export default AuthWrapper;