const API_ACTIONS = {
    requestStart: 'API:requestStart',
    requestEnd: 'API:requestEnd',
    requestSuccess: 'API:requestSuccess',
    requestError: 'API:requestError',

    logIn: 'API:login',
    refreshToken: 'API:refreshToken',

    getGoals: 'API:getGoals',
    getUsers: 'API:getUsers',
    getInvite: 'API:getInvite',
    getPairs: "API:getPairs",
    updateUserWitness: 'API:updateUserWitness',
    updateUserWitnessList: 'API:updateUserWitnessList',
    sendInvites: 'API:sendInvites',
};

const AUTH_ACTIONS = {
    logIn: 'AUTH:login',
    logInQuick: 'AUTH:loginQuick',
    logInSuccess: 'AUTH:loginSuccess',
    logInError: 'AUTH:loginError',
    logOut: 'AUTH:logout',

    refreshToken: 'AUTH:refreshToken',
    refreshTokenSuccess: 'AUTH:refreshTokenSuccess',
    refreshTokenError: 'AUTH:refreshTokenError',
    refreshState: 'AUTH:refreshState',
    refreshCall: 'AUTH:refreshCall',
}

const DATA_ACTIONS = {
    getGoals: 'DATA:getGoals',
    getGoalsSuccess: 'DATA:getGoalsSuccess',
    getGoalsError: 'DATA:getGoalsError',
    setCurrentGoal: 'DATA:setCurrentGoal',
    clearCurrentGoal: 'DATA:clearCurrentGoal',
    resetGoalWitnessList: 'DATA:resetGoalWitnessList',

    setGoalWitnessList: 'DATA:setGoalWitnessList',
    getUsers: 'DATA:getUsers',
    getUsersSuccess: 'DATA:getUsersSuccess',
    getUsersError: 'DATA:getUsersError',
    userDialog: 'DATA:userDialog',
    updateUserWitnessSuccess: 'DATA:updateUserWitnessSuccess',
    updateUserWitnessError: 'DATA:updateUserWitnessError',
    pageChange: 'DATA:pageChange',
    
    getInvite: 'DATA:getInvite',
    invitesSendSuccess: 'DATA:invitesSendSuccess',
    getInvitesSuccess: 'DATA:getInvitesSuccess',
    invitesSendError: 'DATA:invitesSendError',
    dismissMessage: 'DATA:dismissMessage',

    getPairs: 'DATA:getPairs',
    getPairsSuccess: 'DATA:getPairsSuccess',
    getPairsError: 'DATA:getPairsError',
    setCurrentPairs: 'DATA:setCurrentPairs',
    clearCurrentPairs: 'DATA:clearCurrentPairs',
};

const FILTERING_ACTIONS = {
    users: 'FILTER:users',
    goals: 'FILTER:goals',
    pairs: 'FILTER:pairs',
    resetUsers: 'FILTER:resetUsers',
    resetGoals: 'FILTER:resetGoals',
    resetAll: 'FILTER:resetAll'
};

const NOTICE_ACTIONS = {
    invitesDismiss: 'NOTICE:invitesDismiss',
    usersDismiss: 'NOTICE:usersDismiss',
    authDismiss: 'NOTICE:authDismiss',
    goalsDismiss: 'NOTICE:goalsDismiss',
    pairsDismiss: 'NOTICE:pairsDismiss',
};

export {
    API_ACTIONS,
    AUTH_ACTIONS,
    DATA_ACTIONS,
    FILTERING_ACTIONS,
    NOTICE_ACTIONS
}