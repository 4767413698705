import produce from "immer";

const loadingHandler = (state, isLoading) => {
    return produce(state, draft => {
        draft.isLoading = isLoading;
        draft.error = undefined;
        draft.message = undefined;
    });
}

const errorHandler = (state, payload) => {
    return produce(state, draft => {
        draft.isLoading = false;
        draft.error = payload;
    });
};

const dismissNotices = (state) => {
    return produce(state, draft => {
        draft.error = undefined;
        draft.message = undefined;
    });
};

const itemsHandler = (state, payload) => {
    return produce(state, draft => {
        draft.items = payload.content;
        draft.totalElements = payload.totalElements;
        draft.numberOfElements = payload.numberOfElements;
        draft.totalPages = payload.totalPages;
        draft.pageNumber = payload.number;
        draft.isLoading = false;
    });
}

export {loadingHandler, errorHandler, dismissNotices, itemsHandler}