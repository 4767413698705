import React, {Component} from 'react';
import {ErrorBoundary, Theme, Routing} from "./containers";
import {BrowserRouter} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {connect} from "react-redux";
import {quickLoginAction} from "./actions";

class App extends Component {
    componentDidMount() {
        this.props.tryQuickLogin();
    }

    render() {
        return (
            <>
                <CssBaseline/>
                <ErrorBoundary>
                    <Theme>
                        <BrowserRouter>
                            <Routing/>
                        </BrowserRouter>
                    </Theme>
                </ErrorBoundary>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    tryQuickLogin: () => quickLoginAction(dispatch)
})

export default connect(undefined, mapDispatchToProps)(App);