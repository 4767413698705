import { ACTION_TYPES } from "../constants";
import {
  dismissNotices,
  errorHandler,
  itemsHandler,
  loadingHandler,
} from "./_helpers";

const initialState = {
  items: [],
  isLoading: false,
  error: undefined,
  message: undefined,
  totalPages: 0,
  pageNumber: 0,
  totalElements: 0,
  numberOfElements: 0,
};

const apiActions = ACTION_TYPES.API_ACTIONS;
const dataActions = ACTION_TYPES.DATA_ACTIONS;
const noticeActions = ACTION_TYPES.NOTICE_ACTIONS;

const getInvitesSuccessHandler = itemsHandler;

const invitesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case apiActions.sendInvites: {
      return loadingHandler(state, true);
    }

    case dataActions.invitesSendSuccess: {
      return loadingHandler(state, false);
    }
    case dataActions.invitesSendError: {
      return errorHandler(state, payload);
    }

    case noticeActions.invitesDismiss: {
      return dismissNotices(state);
    }

    case dataActions.getInvitesSuccess: {
      return getInvitesSuccessHandler(state, payload);
    }

    case dataActions.getUsersError: {
      return errorHandler(state, payload);
    }

    default:
      return state;
  }
};

export default invitesReducer;
