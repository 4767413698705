import React from 'react';
import propTypes from "prop-types";
import {Box} from "@mui/material";
import "./imageBox.css";

const ImageBox = (props) => {
    const {src, alt, styles} = props;
    if (!src) return null;

    return (
        <Box sx={styles} className="imageBox">
            <img src={src} alt={alt}/>
        </Box>
    );
};

ImageBox.propTypes = {
    src: propTypes.string.isRequired,
    alt: propTypes.string.isRequired,
    styles: propTypes.object,
}

export default ImageBox;