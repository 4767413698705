//Debouncer for fetching data from server

import {ACTION_TYPES} from "../../constants";
import {getGoalsHandler, getInviteHandler, getPairsHandler, getUsersHandler, pageChangeHandler} from "./handlers";

const dataActions = ACTION_TYPES.DATA_ACTIONS;

const dataMiddleware = store => {
    return next => action => {
        const result = next(action);
        const dispatch = store.dispatch;
        const {payload, type} = action;
        const state = store.getState();

        switch (type) {
            case (dataActions.getGoals):
                getGoalsHandler(dispatch, payload);
                break;

            case (dataActions.getPairs):
                getPairsHandler(dispatch, payload);
                break;

            case (dataActions.getUsers):
                getUsersHandler(dispatch, payload);
                break;

            case (dataActions.getInvite):
                getInviteHandler(dispatch, payload);
                break;

            case (dataActions.pageChange):
                pageChangeHandler(dispatch, payload, state);
                break
            default:
                break;
        }

        return result;
    }
}

export default dataMiddleware;