import React from 'react';
import propTypes from "prop-types";
import {TextField} from "@mui/material";
import {useField} from "formik";
import PasswordField from "./passwordField";

const FormikField = (props) => {
    const {type, ...restProps} = props;
    const [field, meta] = useField(props);

    switch (type) {
        case ('text'):
            return <TextField fullWidth
                              error={meta.touched && Boolean(meta.error)}
                              helperText={meta.touched && meta.error}
                              {...restProps}
                              {...field}
                              type={type}
                              variant="outlined"/>

        case ('password'):
            return <PasswordField error={meta.touched && Boolean(meta.error)}
                                  helperText={meta.touched && meta.error}
                                  {...restProps}
                                  {...field}
                                  type={type}/>
        default:
            return null
    }
};

FormikField.propTypes = {
    type: propTypes.string.isRequired,
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    label: propTypes.string
}

export default FormikField;