/*
const API_URL = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_API_URL :
    process.env.REACT_APP_API_URL;
*/

const API_URL = 'https://api.dev.ourgoalclub.com';

const PUBLIC_URL = (process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_PUBLIC_DEV_URL
    : process.env.REACT_APP_PUBLIC_URL) || 'http://localhost:3000';

const ROUTES = {
    login: {
        method: 'post',
        path: '/auth/login',
        vars: ['email', 'password']
    },
    refreshToken: {
        method: 'post',
        path: '/auth/refresh-token',
        vars: ['refresh_token']
    },
    getGoals: {
        method: 'get',
        path: '/admin/goals',
        vars: ['name', 'size', 'page']
    },
    getUsers: {
        method: 'get',
        path: '/admin/users',
        vars: ['name', 'size', 'page']
    },
    getInvite: {
        method: 'get',
        path: '/admin/users/inactive',
        vars: ['name', 'size', 'page']
    },
    getPairs: {
        method: 'get',
        path: '/admin/goals/pair',
        vars: ['size', 'page']
    },
    updateUserWitness: {
        method: 'put',
        path: '/admin/goals/$goalId/witness/$witnessId',
        vars: ['goalId', 'witnessId'],
        pathVars: ['goalId', 'witnessId']
    },
    updateUserWitnessList: {
        method: 'put',
        path: '/admin/goals/assign-witness',
        vars: [],
    },
    sendInvites: {
        method: 'post',
        path: '/admin/users/invite',
        vars: ['emails']
    },
    getGlobalMessage: {
        method: 'get',
        path: '/admin/users/group/message'
    },
    setGlobalMessage: {
        method: 'put',
        path: '/admin/users/group/message',
        vars: ['message'],
        customPut: true
    }
};


export {API_URL, PUBLIC_URL, ROUTES}