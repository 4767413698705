import {ACTION_TYPES} from "../constants";

const actions = ACTION_TYPES.AUTH_ACTIONS;

const _loginHandler = (payload) => {
    return {
        type: actions.logIn,
        payload
    }
}

const loginAction = (dispatch, values) => {
    return dispatch(_loginHandler(values));
};

const quickLoginAction = (dispatch) => {
    return dispatch({
        type: actions.logInQuick
    })
};

const refreshStateAction = (dispatch, payload) => {
    return dispatch({
        type: actions.refreshState,
        payload
    });
}

const refreshCallAction = (dispatch, payload) => {
    return dispatch({
        type: actions.refreshCall,
        payload
    })
};

const refreshTokenSuccessHandler = ({data}) => ({
    type: actions.refreshTokenSuccess,
    payload: data
});

const refreshTokenErrorHandler = (response) => {
    return {
        type: actions.refreshTokenError,
        payload: response.message
    }
}


export {
    loginAction,
    quickLoginAction,
    refreshStateAction,
    refreshCallAction,
    refreshTokenSuccessHandler,
    refreshTokenErrorHandler
}