import React from "react";
import GlobalMessagePage from "./views/pages/global-message";

const HomePage = React.lazy(() => import('./views/pages/home'));
const LoginPage = React.lazy(() => import('./views/pages/login'));
const Page404 = React.lazy(() => import('./views/pages/404'));
const InvitePage = React.lazy(()=>import('./views/pages/invite'));
const PairsPage = React.lazy(()=>import("./views/pages/pairs"));

const routes = [
    {path: '/', name: 'Home page. Goals', key: 'page-home', element: <HomePage/>, authRequired: true},
    {path: 'login', name: 'Log In', key: 'page-login', element: <LoginPage/>},
    {path: 'invite', name: 'Invite', key: 'page-invite', element: <InvitePage/>},
    {path: 'pairs', name: 'Pairs', key: 'page-pairs', element: <PairsPage/>},
    {path: 'message', name: 'Message', key: 'page-message', element: <GlobalMessagePage/>},
    {path: '*', name: '404', key: 'page-404', element: <Page404/>}

];

export default routes;