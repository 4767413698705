import React, {useState} from 'react';
import {
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const PasswordField = (props) => {

    const {error, helperText, label, ...restProps} = props;

    const [visibility, changeVisibility] = useState(false);

    const clickVisibilityHandler = () => {
        changeVisibility(!visibility);
    }

    const mouseDownHandler = (e) => {
        e.preventDefault();
    };

    return <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <OutlinedInput
            error={error}
            label={label}
            {...restProps}
            type={visibility ? 'text' : 'password'}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={clickVisibilityHandler}
                        onMouseDown={mouseDownHandler}
                        edge="end">
                        {visibility ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                </InputAdornment>
            }/>
        <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>

};

export default PasswordField;