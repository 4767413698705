import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid, TextField} from "@mui/material";

import {connect, useSelector} from "react-redux";
import {Notifications} from "../../components";
import {makeGetGlobalMessageRequest, makeSetGlobalMessageRequest} from "../../middleware/api/handlers";


const GlobalMessage = () => {

    const isAuthenticated = useSelector(({auth}) => auth.bearerToken);
    const [reqStatus, setReqStatus] = useState({loading: false, error: null, loaded: false});
    const SRS = (step, value) => {
        const Default = {loading: false, error: false, loaded: false};
        Default[step] = value;
        setReqStatus(Default)
    }
    const [message, setMessage] = useState(null);
    const handleChange = (e) => {
        const {value} = e.target;
        setMessage(value.slice(0,254));
    }
    const handleCreate = () => {
        SRS("loading", true);
        makeSetGlobalMessageRequest(
            () => {
                SRS("loaded", "Global message create");
            },
            () => {
                SRS("error", "Unknown error");
            }, {message});

    }

    useEffect(() => {
        const setMsg = async () => {
            if (isAuthenticated) {
                SRS("loading", true);
                makeGetGlobalMessageRequest(
                    (res) => {
                        SRS("loaded", true);
                        setMessage(res.data.message)
                    },
                    () => {
                        SRS("error", "Unknown error");
                    });
            }

        }
        setMsg();
    }, [isAuthenticated])

    return (
        <div>
            {reqStatus.loading
                ? <Box width="100%" padding="1rem 0" display="flex" justifyContent="center" flexGrow={1}>
                    <CircularProgress/>
                </Box>
                : <Grid container justifyContent="flex-end">
                <TextField value={message} onChange={handleChange} fullWidth multiline={true} maxRows={20}
                           minRows={3}
                           label="Enter global message for users"
                           id="fullWidth"/>

                <Button onClick={handleCreate} sx={{marginTop: "15px"}} variant="contained" color={"primary"}
                        size="large">
                    Create
                </Button>
            </Grid>
            }

            <Notifications open={reqStatus.error || reqStatus.loaded.length}
                           type={reqStatus.error ? "error" : "success"}
                           message={reqStatus.error || reqStatus.loaded}/>
        </div>
    );
};

export default connect()(GlobalMessage);

