import {combineReducers} from "redux";
import authReducer from "./auth.red";
import usersReducer from "./users.red";
import goalsReducer from "./goals.red";
import filtersReducer from "./filters.red";
import paginationReducer from './pagination.red';
import invitesReducer from "./invites.red";
import pairsReducer from './pairs.red';

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    goals: goalsReducer,
    filters: filtersReducer,
    pagination: paginationReducer,
    invites: invitesReducer,
    pairs: pairsReducer
});

export default rootReducer;