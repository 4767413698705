import {ACTION_TYPES} from "../constants";
import { DATA_ACTIONS } from "../constants/action-types";

const apiActions = ACTION_TYPES.API_ACTIONS;

const sendInvitesAction = emails => {
    return dispatch => dispatch({
        type: apiActions.sendInvites,
        payload: {
            emails
        }
    })
};

const getInviteAction = (payload) => {
    return (dispatch) =>
      dispatch({
        type: DATA_ACTIONS.getInvite,
        payload,
      });
  };

export {sendInvitesAction, getInviteAction};