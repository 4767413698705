import { ACTION_TYPES } from "../constants";

const dataActions = ACTION_TYPES.DATA_ACTIONS;
const apiActions = ACTION_TYPES.API_ACTIONS;

const _getUsersHandler = (payload) => {
  return {
    type: dataActions.getUsers,
    payload,
  };
};

const userDialogAction = (payload) => {
  return (dispatch) =>
    dispatch({
      type: dataActions.userDialog,
      payload,
    });
};

const getUsersAction = (payload) => {
  return (dispatch) => dispatch(_getUsersHandler(payload));
};

const putGoalWitnessList = (payload) => {
  return (dispatch) =>
    dispatch({ type: apiActions.updateUserWitnessList, payload });
};

const updateUserAction = (payload) => (dispatch) =>
  dispatch({
    type: dataActions.setGoalWitnessList,
    payload,
  });

export { userDialogAction, getUsersAction, updateUserAction, putGoalWitnessList };
