import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {TextField, InputAdornment, IconButton} from "@mui/material";

const EndAdornment = props => {
    const {onClick} = props;

    const onMouseDown = e => e.preventDefault();

    return <InputAdornment position="end">
        <IconButton onMouseDown={onMouseDown}
                    edge="end"
                    onClick={onClick}>
            <ClearIcon/>
        </IconButton>
    </InputAdornment>;
}

EndAdornment.propTypes = {}

const FilterView = (props) => {
    const {showClear, onClearClick, ...rest} = props;

    const endAdornment = showClear && props.value && <EndAdornment onClick={onClearClick}/>

    return <TextField {...rest}
                      InputProps={{endAdornment: endAdornment}}/>;
};

export default FilterView;