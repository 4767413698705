import {makeRequest} from "../../helpers/_api";
import store from "../../store";
import {ACTION_TYPES} from "../../constants";
import {refreshTokenErrorHandler, refreshTokenSuccessHandler} from "../../actions";
import {errorMessageHandler} from "../_helpers";

const authActions = ACTION_TYPES.AUTH_ACTIONS;
const dataActions = ACTION_TYPES.DATA_ACTIONS;

const makeLoginRequest = (payload) => {
    const routeKey = 'login';
    const result = makeRequest(routeKey, payload);

    result.then(_loginSuccessHandler, _loginErrorHandler);
}

const _loginSuccessHandler = ({data}) => {
    store.dispatch({
        type: authActions.logInSuccess,
        payload: data
    });
}

const _loginErrorHandler = (resp) => {
    store.dispatch({
        type: authActions.logInError,
        payload:  errorMessageHandler(resp)
    });
};

const makeRefreshTokenRequest = (payload, dispatch) => {
    const routeKey = 'refreshToken';
    const result = makeRequest(routeKey, payload, true);

    result.then(
        resp => _refreshTokenSuccessHandler(resp, dispatch),
        resp => _refreshTokenErrorHandler(resp, dispatch))
};

const _refreshTokenSuccessHandler = (response, dispatch) => {
    dispatch(refreshTokenSuccessHandler(response));
};

const _refreshTokenErrorHandler = (response, dispatch) => {
    dispatch(refreshTokenErrorHandler(response));
};

const makeGetGoalsRequest = (payload, dispatch) => {
    const routeKey = 'getGoals';
    const result = makeRequest(routeKey, payload);

    result.then(
        resp => _getGoalsSuccessHandler(resp, dispatch),
        resp => _getGoalsErrorHandler(resp, dispatch))
};

const makeGetPairsRequest = (payload, dispatch) => {
    const routeKey = 'getPairs';
    const result = makeRequest(routeKey, payload);

    result.then(
        resp => _getPairsSuccessHandler(resp, dispatch),
        resp => _getPairsErrorHandler(resp, dispatch))
};

const _getGoalsSuccessHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.getGoalsSuccess,
        payload: resp.data
    })
};

const _getGoalsErrorHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.getGoalsError,
        payload: errorMessageHandler(resp)
    })
};

const _getPairsSuccessHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.getPairsSuccess,
        payload: resp.data
    })
};

const _getPairsErrorHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.getPairsError,
        payload: errorMessageHandler(resp)
    })
};


const makeGetUsersRequest = (payload, dispatch) => {
    const routeKey = 'getUsers';
    const result = makeRequest(routeKey, payload);
    result.then(
        resp => _getUsersSuccessHandler(resp, dispatch),
        resp => _getUsersErrorHandler(resp, dispatch)
    )
};

const _getUsersSuccessHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.getUsersSuccess,
        payload: resp.data
    })
}

const _getUsersErrorHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.getUsersError,
        payload: errorMessageHandler(resp)
    })
}

const makeGetInviteRequest = (payload, dispatch) => {
    const routeKey = 'getInvite';
    const result = makeRequest(routeKey, payload);

    result.then(
        (resp) =>
          dispatch({
            type: dataActions.getInvitesSuccess,
            payload: resp.data,
          }),
        (resp) =>
          dispatch({
            type: dataActions.invitesSendError,
            payload: errorMessageHandler(resp),
          })
      );
};

const makeUpdateUserWitnessRequest = (payload, dispatch) => {
    const routeKey = 'updateUserWitness';
    const result = makeRequest(routeKey, payload);
    result.then(
        resp => _updateWitnessSuccessHandler(resp, dispatch),
        resp => _updateWitnessErrorHandler(resp, dispatch)
    );
}

const putGoalWitnessList = (payload, dispatch) => {
    const routeKey = 'updateUserWitnessList';
    makeRequest(routeKey, payload)
    .then((res) => {
        dispatch({
            type: dataActions.resetGoalWitnessList,
        });
    })
}

const _updateWitnessSuccessHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.updateUserWitnessSuccess,
    });
}

const _updateWitnessErrorHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.updateUserWitnessError,
        payload: errorMessageHandler(resp)
    });
};

const makeSendInvitesRequest = (payload, dispatch) => {
    const routeKey = 'sendInvites';
    const result = makeRequest(routeKey, payload);
    result.then(
        resp => _sendInvitesSuccessHandler(resp, dispatch),
        resp => _sendInvitesErrorHandler(resp, dispatch)
    )
};

const _sendInvitesSuccessHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.invitesSendSuccess
    })
};

const _sendInvitesErrorHandler = (resp, dispatch) => {
    dispatch({
        type: dataActions.invitesSendError,
        payload: errorMessageHandler(resp)

    })
}
const makeGetGlobalMessageRequest = ( handlerSuccess, handlerError) => {
    const routeKey = 'getGlobalMessage';
    const result = makeRequest(routeKey);

    result.then(handlerSuccess, handlerError);
}
const makeSetGlobalMessageRequest = (handlerSuccess, handlerError, payload) => {
    const routeKey = 'setGlobalMessage';
    const result = makeRequest(routeKey, payload);

    result.then(handlerSuccess, handlerError);
}



export {
    makeLoginRequest,
    makeRefreshTokenRequest,
    makeGetGoalsRequest,
    makeGetInviteRequest,
    makeGetUsersRequest,
    makeUpdateUserWitnessRequest,
    makeSendInvitesRequest,
    putGoalWitnessList,
    makeGetPairsRequest,
    makeGetGlobalMessageRequest,
    makeSetGlobalMessageRequest
};