import {ACTION_TYPES} from "../constants";

const actions = ACTION_TYPES.FILTERING_ACTIONS;

const filterGoalsAction = (payload) => {
    return dispatch => dispatch({
        type: actions.goals,
        payload
    })
};

const filterUsersAction = (payload) => {
    return dispatch => dispatch({
        type: actions.users,
        payload
    })
};

const filterResetUsersActions = () => {
    return dispatch => dispatch({
        type: actions.resetUsers
    })
}
const filterResetGoalsActions = () => {
    return dispatch => dispatch({
        type: actions.resetGoals
    })
}

const resetAllFiltersAction = () => {
    return dispatch => dispatch({
        type: actions.resetAll
    })
}

export {
    filterGoalsAction,
    filterUsersAction,
    filterResetGoalsActions,
    filterResetUsersActions,
    resetAllFiltersAction
};
