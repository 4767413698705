import React from 'react';
import routes from "../routes";
import {Route, Routes} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {AuthWrapper, Layout} from "./index";

const Routing = (props) => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                {routes.map(route => {
                    return (
                        <Route {...route}
                               element={
                                   <React.Suspense fallback={<CircularProgress/>}>
                                       <AuthWrapper requireAuth={route.authRequired}>
                                           {route.element}
                                       </AuthWrapper>
                                   </React.Suspense>
                               }/>
                    )
                })}

            </Route>
        </Routes>
    );
};

export default Routing;