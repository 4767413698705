import {
    makeGetGoalsRequest,
    makeGetInviteRequest, makeGetPairsRequest,
    makeGetUsersRequest,
    makeLoginRequest,
    makeRefreshTokenRequest, makeSendInvitesRequest,
    makeUpdateUserWitnessRequest,
    putGoalWitnessList
} from "./handlers";
import {ACTION_TYPES} from "../../constants";

const actions = ACTION_TYPES.API_ACTIONS;

const apiMiddleware = store => {
    return next => action => {
        const dispatch = store.dispatch;
        const result = next(action);
        const {payload, type} = action;
        switch (type) {
            case (actions.logIn):
                makeLoginRequest(payload);
                break;

            case (actions.refreshToken):
                makeRefreshTokenRequest(payload, dispatch);
                break;

            case (actions.getGoals):
                makeGetGoalsRequest(payload, dispatch);
                break;

            case (actions.getUsers):
                makeGetUsersRequest(payload, dispatch);
                break;

            case (actions.getInvite):
                makeGetInviteRequest(payload, dispatch);
                break;

            case (actions.getPairs):
                makeGetPairsRequest(payload, dispatch);
                break;

            case (actions.updateUserWitness): {
                makeUpdateUserWitnessRequest(payload, dispatch)
                break;
            }

            case (actions.updateUserWitnessList): {
                putGoalWitnessList(payload, dispatch)
                break;
            }

            case (actions.sendInvites): {
                makeSendInvitesRequest(payload, dispatch);
                break;
            }

            default:
                break;
        }

        return result;
    }
}

export default apiMiddleware;