import produce from "immer";
import {ACTION_TYPES} from "../constants";

const dataActions = ACTION_TYPES.DATA_ACTIONS;
const filterActions = ACTION_TYPES.FILTERING_ACTIONS;

const defaultFiltering = {
    page: 0,
    size: 9,
    name: ''
};

const initialState = {
    goals: defaultFiltering,
    pairs: defaultFiltering,
    users: {...defaultFiltering, size: 16},
    invite: defaultFiltering,
};

const _typesUpdateHandler = (state, payload, type) => {
    return produce(state, draft => {
        //Page numbers start with 0
        draft[type].page = payload.number;
        draft[type].size = payload.size;
    })
};

const _resetFiltersHandler = (state, payload, entity) => {
    return produce(state, draft=>{
        draft[entity] = initialState[entity];
    });
}

const filtersReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case (dataActions.getGoalsSuccess): {
            return _typesUpdateHandler(state, payload, 'goals');
        }

        case (dataActions.getUsersSuccess): {
            return _typesUpdateHandler(state, payload, 'users');
        }

        case (dataActions.getPairsSuccess): {
            return _typesUpdateHandler(state, payload, 'pairs');
        }

        case (dataActions.getInvitSuccess): {
            return _typesUpdateHandler(state, payload, 'invite');
        }

        case (filterActions.resetUsers):{
            return _resetFiltersHandler(state, payload, 'users');
        }


        default:
            return state;
    }
}

export default filtersReducer;