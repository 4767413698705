import {ACTION_TYPES} from "../constants";

const dataActions = ACTION_TYPES.DATA_ACTIONS;

const _getGoalsHandler = (payload) => {
    return {
        type: dataActions.getGoals,
        payload
    }
}

const getGoalsAction = (payload) => {
    return dispatch => dispatch(_getGoalsHandler(payload))
};

const setCurrentGoalAction = payload => {
    return dispatch =>dispatch({
        type: dataActions.setCurrentGoal,
        payload
    })
};

const clearCurrentGoalAction = () => {
    return dispatch =>dispatch({
        type: dataActions.clearCurrentGoal
    })
}

export {getGoalsAction, setCurrentGoalAction, clearCurrentGoalAction};