import {ACTION_TYPES} from "../constants";

const dataActions = ACTION_TYPES.DATA_ACTIONS;

const _changePageHandler = (pageNumber, type) => {
    return {
        type: dataActions.pageChange,
        payload: {
            page: pageNumber,
            type
        }
    }
}

const userChangePageAction = pageNumber => {
    return dispatch => dispatch(_changePageHandler(pageNumber, 'users'))
};

const goalsChangePageAction = (pageNumber) => {
    return dispatch => dispatch(_changePageHandler(pageNumber, 'goals'))
}

const inviteChangePageAction = (pageNumber) => {
    return dispatch => dispatch(_changePageHandler(pageNumber, 'invite'))
}

const pairsChangePageAction = (pageNumber) => {
    return dispatch => dispatch(_changePageHandler(pageNumber, 'pairs'))
}

export {
    userChangePageAction,
    goalsChangePageAction,
    inviteChangePageAction,
    pairsChangePageAction
};