import React from 'react';
import {Page} from "../../../components";
import GlobalMessage from "../../global-message";
/*import {getPairsAction} from "../../../actions";*/
const title = "Create global message"
const GlobalMessagePage = () => {
    return (
        <Page title={title}>
            <GlobalMessage/>

        </Page>
    );
};

/*
const mapStateToProps = state => ({
    isLoading: state.message.isLoading
});

const mapDispatchToProps = dispatch => ({
    createMessage: payload => dispatch(getPairsAction(payload))
});
*/

export default GlobalMessagePage;