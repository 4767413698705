import React from 'react';
import {Box, useTheme, MenuList, MenuItem, ListItemText, Link} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import nav from './_nav';
import {Logo} from "../components";
import {useSelector} from "react-redux";

const Sidebar = () => {
    const {palette, sidebarWidth} = useTheme();
    const isLoggedIn = useSelector(state=>state.auth.isLoggedIn);
    if (!isLoggedIn) return null;

    return (
        <Box className="c-sidebar" sx={{
            backgroundColor: palette.background.default,
            padding: '1rem',
            width: sidebarWidth
        }}
             id="sidebar">
            <Box sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Link to="/" component={RouterLink}>
                    <Logo sx={{width: '200px', filter: 'invert(1)'}}/>
                </Link>
            </Box>

            <MenuList>
                {nav.map(item => (
                    <MenuItem component={RouterLink}
                              to={item.href}
                              onClick={item.onClick}
                              sx={item.sx}
                              key={item.key}>
                        <ListItemText variant="h6" tag="div">
                            {item.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </MenuList>
        </Box>
    );
};

export default Sidebar;