import produce from "immer";
import {ACTION_TYPES} from "../constants";

const dataActions = ACTION_TYPES.DATA_ACTIONS;

const defaultPagination = {
    totalPages: 0,
    number: 0,
    size: 0,
    firstPage: true,
    lastPage: false
};

const initialState = {
    goals: defaultPagination,
    pairs: defaultPagination,
    users: {...defaultPagination, size: 16}
}

const _getPaginatedItemsSuccess = (state, payload, type) => {
    return produce(state, draft => {
        draft[type].totalPages = payload.totalPages;
        draft[type].number = payload.number;
        draft[type].size = payload.size;
        draft[type].firstPage = payload.first;
        draft[type].lastPage = payload.last;
    })
};

const _getUsersSuccess = (state, payload) => _getPaginatedItemsSuccess(state, payload, 'users');

const _getGoalsSuccess = (state, payload) => _getPaginatedItemsSuccess(state, payload, 'goals');

const _getPairsSuccess = (state, payload) => _getPaginatedItemsSuccess(state, payload, 'pairs');

const paginationReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case (dataActions.getUsersSuccess): {
            return _getUsersSuccess(state, payload)
        }

        case (dataActions.getGoalsSuccess): {
            return _getGoalsSuccess(state, payload)
        }

        case (dataActions.getPairsSuccess): {
            return _getPairsSuccess(state, payload)
        }

        default:
            return state;
    }
}

export default paginationReducer;