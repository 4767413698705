import Cookies from 'js-cookie';
import store from "../store";
import {ACTION_TYPES} from "../constants";

const saveLoginTokens = (data) => {
    const refreshToken =  data.refreshToken || data.refresh_token;
    _setCookie('bearerToken', data.bearerToken)
    _setCookie('refreshToken', refreshToken);
};

const _setCookie = (name, value) => {
    Cookies.set(name, value, {path: '/'});
}

const getLoginTokens = () => {
    const refreshToken = _getCookie('refreshToken');
    const bearerToken = _getCookie('bearerToken');

    return {refreshToken, bearerToken};
}

const _getCookie = (name) => {
    return Cookies.get(name, {path: '/'});
}

const destroyLoginTokens = () => {
    _removeCookie('bearerToken');
    _removeCookie('refreshToken');
}

const _removeCookie = (name) => {
    Cookies.remove(name, {path: '/'});
}

const logOut = () => {
    store.dispatch({
        type: ACTION_TYPES.AUTH_ACTIONS.logOut
    });
}

export {
    saveLoginTokens,
    getLoginTokens,
    destroyLoginTokens,
    logOut
};