import React, {Component} from 'react';
import {Container, Typography} from "@mui/material";

class ErrorBoundary extends Component {
  state = {
    errorMessage: null
  }

  static getDerivedStateFromError(error) {
    return {
      errorMessage: error.toString()
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
  }


  render() {

    if (this.state.errorMessage) {
      return <Container className="p-3">
        <Typography variant="h2">
          Something went wrong
        </Typography>
        <Typography variant="h3">
          {this.state.errorMessage}
        </Typography>
        <p>Try refreshing the page!</p>
      </Container>
    }

    return this.props.children
  }
}

export default ErrorBoundary;
