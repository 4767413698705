import React from 'react';
import propTypes from "prop-types";
import {Snackbar, Alert} from "@mui/material";

const Notifications = (props) => {
    const {
        open,
        message,
        type,
        onDismiss
    } = props;

    return (
        <Snackbar open={open}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                  onClose={onDismiss}>
            {type ? <Alert onClose={onDismiss}
                           sx={{maxWidth: '300px'}}
                           variant="filled"
                           severity={type}>{message}</Alert> : message}
        </Snackbar>
    );
};

Notifications.propTypes = {
    open: propTypes.bool,
    message: propTypes.string,
    type: propTypes.oneOf(['error', 'warning', 'info', 'success']),
    onDismiss: propTypes.func
};

export default Notifications;