import {createStore, compose, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {
    apiMiddleware,
    authMiddleware,
    dataMiddleware
} from './middleware';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    storeEnhancers(
        applyMiddleware(
            thunk,
            apiMiddleware,
            authMiddleware,
            dataMiddleware
        )
    )
)
window.store = store;
export default store;