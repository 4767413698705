import React from 'react';
import propTypes from "prop-types";
import classes from 'classnames';
import {Box, Typography, useTheme} from "@mui/material";
import './page.css';

const Page = (props) => {
    const {children, styles, className, title} = props;
    const {palette} = useTheme();
    const classNames = classes('c-page', className);

    return (
        <Box className={classNames} sx={{
            backgroundColor: palette.background.default,
            ...styles
        }}>

            {title && <Typography variant="h4"
                                  component="h1"
                                  sx={{borderBottom: '1px solid',
                                      marginBottom: '0.75rem'}}>
                {title}
            </Typography>}
            {children}
        </Box>
    );
};

Page.propTypes = {
    title: propTypes.string,
    className: propTypes.string,
    styles: propTypes.object,
}

export default Page;